/** @format */

import React from "react";
import nl2br from "react-nl2br";
import Link from "next/link";
// Apply Form
import JobApplyForm from "./JobApplyForm";
import { createJobApply } from "../../lib/db/data-apply";
import { Condition } from "../../types/strapi/condition";
import { Company } from "../../types/strapi/company";
import CompanyLinkBox from "../company/CompanyLinkBox";
import { Job } from "../../types/strapi/job";
import useLocale from "../../hooks/useLocale";

// Main block
const JobDetail = ({ job }) => {
  const {
    company_name,
    company_capital,
    company_employee,
    company_description,
    id,
    title,
    position,
    working_status,
    working_description,
    background,
    working_hours,
    working_location,
    remote_work,
    min_salary,
    max_salary,
    salary_description,
    images,
    conditions,
    description,
    requirements,
    welfare,
    holiday,
    salary_duration,
    note,
  } = job as Job;
  const company = job.company as Company;
  const { t } = useLocale();

  const backendUrl = process.env.NEXT_PUBLIC_STRAPI_API_URL;

  // Thumbnail format
  const thumbnailUrl = images
    ? backendUrl + images[0]?.url
    : "https://via.placeholder.com/800x600?text=Not%20Found";

  // Salary format
  let salary = min_salary ? min_salary.toLocaleString()
   : "";
  max_salary
    ? (salary = salary + " - " + max_salary.toLocaleString())
    : "";
  return (
    <div>
      {/* <!-- Post --> */}
      <section className='relative'>
        {job && (
          <div className='container' key={id}>
            {/* <!-- Article --> */}
            <article className='dark:bg-jacarta-600 pt-3 pb-10 px-10 rounded-lg'>
              {/* border border-jacarta-100 */}

              <div className='article-content'>
                <h1 className='mb-4 text-2xl text-jacarta-700 dark:text-white sm:text-2xl'>
                  {title}
                </h1>
                {/* <div className='text-xs'>
                  <span className='text-accent'>
                    {position}
                  </span>
                </div> */}

                <div className='mt-4'>
                  {images && images[0]?.url && (
                    <div className='mb-7'>
                      <figure className='mb-4'>
                        <img
                          src={thumbnailUrl}
                          alt={title}
                          className='w-full rounded-2lg max-h-[70vh] object-cover'
                        />
                      </figure>
                    </div>
                  )}

                  <div className='mb-5'>
                    <h3 className='font-display text-md font-semibold text-jacarta-700 dark:text-white'>
                      {t.JOB.CATEGORY}
                    </h3>
                    <div className='text-2xs font-medium dark:text-jacarta-300 py-2'>
                      {conditions &&
                        conditions.map((item: Condition, i: React.Key) => {
                          return (
                            <span
                              className='btn btn-outline btn-xs m-1'
                              key={i}>
                              {item?.name}
                            </span>
                          );
                        })}
                      {/* if conditions is empty */}
                      {conditions.length === 0 && <span>No Category ...</span>}
                    </div>
                  </div>
                  <div className='mb-7'>
                    <div className='mb-7'>
                      <h3 className='font-display text-md font-semibold text-jacarta-700 dark:text-white pb-2'>
                        {
                          t.JOB.COMPANY
                        }
                      </h3>
                      {company ? (
                        <CompanyLinkBox company={company} />
                      ) : (
                        <CompanyLinkBox
                          company={{
                            name: company_name,
                            description: company_description,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className='text-sm breadcrumbs flex justify-center'>
                  <ul>
                    <li>
                      <Link href='/'>
                        <a>Home</a>
                      </Link>
                    </li>
                    <li>{title}</li>
                  </ul>
                </div> */}

                {position && (
                  <>
                    <h2 className='text-3xl'>
                      {t.JOB.POSITION}
                    </h2>
                    <p className='text-lg leading-normal'>
                      <b>{position}</b>
                    </p>
                  </>
                )}
                {working_status && (
                  <>
                    <h2 className='text-3xl'>
                      {t.JOB.WORKING_STATUS}
                    </h2>
                    <p className='text-lg leading-normal'>
                      <b>{working_status}</b>
                    </p>
                  </>
                )}
                {salary && (
                  <>
                    <h2 className='text-3xl'>Salary</h2>
                    <p className='text-lg leading-normal'>
                      <b>{salary}</b>
                      <span className='pl-1 text-sm'>
                        円 / {salary_duration === "monthly" ? "月" : salary_duration === "yearly" ? "年" : ""}
                      </span>
                    </p>
                    <p className='leading-normal'>{salary_description}</p>
                  </>
                )}
                {working_description && (
                  <>
                    <h2 className='text-3xl'>
                      {
                        t.JOB.WORKING_DESCRIPTION
                      }
                    </h2>
                    <p className='leading-normal'>
                      {nl2br(working_description)}
                    </p>
                  </>
                )}
                {working_location && (
                  <>
                    <h2 className='text-3xl'>Working Location</h2>
                    <p className='text-lg leading-normal'>
                      <b>{working_location}</b>
                    </p>
                  </>
                )}
                {working_hours && (
                  <>
                    <h2 className='text-3xl'>Working Hours</h2>
                    <p className='leading-normal'>{nl2br(working_hours)}</p>
                  </>
                )}
                {background && (
                  <>
                    <h2 className='text-3xl'>Background</h2>
                    <p className='leading-normal'>{nl2br(background)}</p>
                  </>
                )}
                {
                  // 必須要件
                  requirements && (
                    <>
                      <h2 className='text-3xl'>
                        {
                          t.JOB.REQUIREMENTS
                        }
                      </h2>
                      <p className='leading-normal'>{nl2br(requirements)}</p>
                    </>
                  )
                }
                {description && (
                  <>
                    <h2 className='text-3xl'>Description</h2>
                    <p className='leading-normal'>{nl2br(description)}</p>
                  </>
                )}

                {welfare && (
                  <>
                    <h2 className='text-3xl'>Welfare</h2>
                    <p className='leading-normal'>{nl2br(welfare)}</p>
                  </>
                )}

                {holiday && (
                  <>
                    <h2 className='text-3xl'>Holiday</h2>
                    <p className='leading-normal'>{nl2br(holiday)}</p>
                  </>
                )}
                {note && (
                  <>
                    <h2 className='text-3xl'>Note</h2>
                    <p className='leading-normal'>{nl2br(note)}</p>
                  </>
                )}
                <JobApplyForm job={job} createJobApply={createJobApply} />
              </div>
            </article>
          </div>
        )}
      </section>
      {/* <!-- end post --> */}
    </div>
  );
};

export default JobDetail;
