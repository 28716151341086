// ReactQuery
import { useQuery } from "react-query";
// Fetch for Strapi
import { fetchAPI } from "../strapi/fetch-api";
import { upsertAPI } from "../strapi/upsert-api";

/**
 * Create
 */
export async function createJobApply(params) {
	const { applicant_name, email, message, job } = params;
	const res = await upsertAPI(
		"applies",
		{
			applicant_name: applicant_name,
			email: email,
			message: message,
			job: job,
		},
		"post"
	);

	console.log("res.log.", res);

	return res;
}

/**
 * Read
 */

/**
 * Update
 */

/**
 * Delete
 */
