// Main block
export async function upsertAPI(
	endpoint,
	inputData = {},
	optMethod = "post",
	options = {},
) {
	// Request
	const request = `${getStrapiURI(`/api/${endpoint}`)}`;

	// Upsert options with strapi token
	const mergedOptions = {
		method: optMethod,
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + process.env.NEXT_PUBLIC_STRAPI_API_TOKEN,
		},
		body: JSON.stringify({ data: inputData }),
		...options,
	};

	// API call
	try {
		const response = await fetch(request, mergedOptions);
		if (!response.ok) {
			throw new Error(`An error occured please try again`);
		}
		return await response.json();
	} catch (err) {
		console.error(err.message);
		throw new Error(`An error occured. please try again`);
	}
}

// Strapi URI
export function getStrapiURI(path = "") {
	return `${
		process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
	}${path}`;
}
