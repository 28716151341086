import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const ToastMsg = (title, msg, type = "success") => {
	MySwal.fire({
		title: title,
		text: msg,
		icon: type,
		timer: 3000,
		timerProgressBar: true,
		showConfirmButton: false,
	});
};

export default ToastMsg;
