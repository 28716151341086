import Link from "next/link";
import nl2br from "react-nl2br";
import { Company } from "../../types/strapi/company";
import { HiOfficeBuilding } from "react-icons/hi";

const CompanyLinkBox = ({ company } : { company: Company }) => {
  return company.id ? (
    <Link href={`/company/${company.id}`}>
      <div className='mt-2 p-4 border rounded bg-jacarta-200 cursor-pointer'>
        <div className='flex'>
          <div className='w-8 h-8'>
            <HiOfficeBuilding className='text-2xl text-jacarta-600' />
          </div>
          <div className='flex flex-col ml-2'>
            <span className='text-md font-bold text-jacarta-600'>
              {company.name}
            </span>
            <p className='text-2xs font-medium dark:text-jacarta-600 mt-1 pr-2'>
              {nl2br(company.description)}
            </p>
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <div className='mt-2 p-4 rounded bg-jacarta-700'>
      <div className='flex'>
        <div className='w-8 h-8'>
          <HiOfficeBuilding className='text-2xl text-white' />
        </div>
        <div className='flex flex-col ml-2'>
          <span className='text-md font-bold text-white'>
            {company.name}
          </span>
          <hr className='my-2 border-jacarta-600 border-1' />
          <p className='text-2xs font-medium border-jacarta-100 mt-1 pr-2'>
            {nl2br(company.description)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyLinkBox;
