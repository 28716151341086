import axios from "axios";

// お問い合わせメール送信
const SendMail = async (apiUrl = "/api/email/send-apply", inputData) => {
	try {
		await axios.post(apiUrl, {
			data: inputData,
			headers: {
				Authorization: process.env.NEXT_PUBLIC_REQUEST_API_X_AUTH,
			},
		});
	} catch (error) {
		console.error('e', error);
	}
};

export default SendMail;
