import React from "react";
import nl2br from "react-nl2br";
// Yup form
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ToastMsg from "../Common/ToastMsg";
import SendMail from "../Common/SendMail";

// Form schema
const schema = yup.object().shape({
	applicant_name: yup.string().min(4).max(32).required(),
	email: yup.string().email().required(),
	message: yup.string().max(400),
});

// Main block
const JobApply = ({ job, createJobApply }) => {
	const { id, title } = job;
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmitForm = async (inputData) => {
		const { applicant_name, email, message } = inputData;
		try {
			const inputDataWithJobId = { ...inputData, job: id };

			// Insert DB
			createJobApply(inputDataWithJobId);

			// Send email
			SendMail("/api/mail/send-apply", {
				subject: `[JobID]:${id} Web3 Job Application`,
				name: `${applicant_name}`,
				email: `${email}`,
				message: `
						[Name]: ${applicant_name}<br>
						[Email]: ${email}<br>
						[JobID]: ${id}<br>
						[Message]: <br>${message}<br>
					`,
			});

			ToastMsg(
				"Successfully applied!",
				"Please wait for the contact from the recruiter.",
				"success"
			);
			reset();
		} catch (err) {
			console.error(err.message);
			throw new Error(`An error occured. please try again`);
		}
	};

	return (
		<div>
			{/* <!-- Post --> */}
			<section>
				<div className="bg-jacarta-700 rounded-lg p-8">
					<h2 className="font-display mb-10 text-center text-4xl font-medium text-white">
						Apply for job
					</h2>
					{title && (
						<p className="font-display pb-5 text-center text-xl font-medium text-white">
							{title}
						</p>
					)}
					<div className="mx-auto max-w-[48.125rem]">
						<form onSubmit={handleSubmit(onSubmitForm)}>
							<div className="mb-6">
								<label
									htmlFor="applicant_name"
									className="font-display mb-2 block text-white"
								>
									Name<span className="text-red">*</span>
								</label>
								<input
									{...register("applicant_name")}
									type="text"
									className="dark:bg-jacarta-600 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
									placeholder="Please enter your full-name"
									required
								/>
								<p className="text-white">
									{errors.applicant_name?.message}
								</p>
							</div>

							<div className="mb-6">
								<label
									htmlFor="email"
									className="font-display mb-2 block text-white"
								>
									Email<span className="text-red">*</span>
								</label>
								<input
									{...register("email")}
									type="email"
									className="dark:bg-jacarta-600 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
									placeholder="Please enter your email address"
									required
								/>
								<p className="text-white">
									{errors.email?.message}
								</p>
							</div>

							<div className="mb-6">
								<label
									htmlFor="messsage"
									className="font-display mb-2 block text-white"
								>
									Message
								</label>
								<textarea
									id="message"
									className="dark:bg-jacarta-600 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
									rows="4"
									placeholder="Please enter a message if you have any questions about recruitment"
									{...register("message")}
								></textarea>
							</div>
              <div className="flex justify-end">
							<button className="btn btn-primary cursor-default rounded-md py-3 px-8 text-center font-semibold text-white transition-all">
								Apply
							</button>
              </div>
						</form>
					</div>
				</div>
			</section>
			{/* <!-- end post --> */}
		</div>
	);
};

export default JobApply;
