/** @format */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HERO: {
    TITLE: "Web3 Recruitment Agency Jobs for web3 Engineers",
    DESCRIPTION1: "Discover",
    DESCRIPTION2: "100+",
    DESCRIPTION3: "remote Web3 Jobs around the world",
    PLACEHOLDER: "Enter your email",
    SUBSCRIBE: "Subscribe",
    SUBSCRIBE_DESCRIPTION: "Receive emails of new Web3 Jobs",
  },
  JOB: {
    CATEGORY: "Categories & Industries",
    COMPANY: "Company",
    POSITION: "Position",
    WORKING_STATUS: "Working Status",
    WORKING_DESCRIPTION: "Working description",
    REQUIREMENTS: "Requirements",
  },
};
