// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HERO: {
    TITLE: "Web3エンジニアのための リクルートメントエージェンシー",
    DESCRIPTION1: "世界中に",
    DESCRIPTION2: "100以上",
    DESCRIPTION3: "あるWeb3の求人情報を見つけよう",
    PLACEHOLDER: "メールアドレス",
    SUBSCRIBE: "登録する",
    SUBSCRIBE_DESCRIPTION: "新しいWeb3の求人情報をメールで受け取る",
  },
  JOB: {
    CATEGORY: "カテゴリー",
    COMPANY: "会社",
    POSITION: "ポジション",
    WORKING_STATUS: "雇用形態",
    WORKING_DESCRIPTION: "業務内容",
    REQUIREMENTS: "必須要件",
  },
};